<template>
  <div>
    <v-app-bar app color="white" height="100">
      <v-toolbar-title class="font-weight-black headline">
        S CAR
      </v-toolbar-title>
      <v-btn plain>
        <a @click="goToHome">Home</a>
      </v-btn>
    </v-app-bar>

    <section ref="contact">
      <v-sheet id="contact" color="#333333" dark tag="section" tile>
        <div class="py-12"></div>

        <v-container>
          <h2
            style="margin-top: 1rem"
            class="display-2 font-weight-bold mb-3 text-uppercase text-center"
          >
            LOGIN
          </h2>

          <v-responsive class="mx-auto mb-12" width="56">
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-theme-provider light>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  flat
                  label="Email*"
                  solo
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="password"
                  type="password"
                  flat
                  label="Senha*"
                  solo
                ></v-text-field>
              </v-col>

              <v-col class="mx-auto" cols="auto">
                <v-btn @click="verifyLogin" color="accent" x-large>
                  ENTRAR
                </v-btn>
              </v-col>
            </v-row>
          </v-theme-provider>
        </v-container>

        <div class="py-12"></div>
      </v-sheet>
    </section>
  </div>
</template>

<script>
import User from "../dominio/User";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    goToHome() {
      this.$router.push({ path: `/` });
    },
    async verifyLogin() {
      let login = await User.Login(this.email, this.password);
      if (login) this.$router.push({ path: `/stock` });
    },
  },
};
</script>

<style></style>
