<template>
  <div>
    <v-app-bar dark>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>Administração</v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="green--text text--accent-4"
        >
          <v-list-item @click="goToStock">
            <v-list-item-title>Estoque</v-list-item-title>
          </v-list-item>

          <v-list-item @click="goToAddAnnouncement">
            <v-list-item-title>Novo anúncio</v-list-item-title>
          </v-list-item>
          <v-list-item @click="goToClients">
            <v-list-item-title>Clientes</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import User from "../dominio/User";
export default {
  name: "AdminMenuBar",
  data() {
    return {
      drawer: false,
      group: null,
    };
  },
  methods: {
    goToAddAnnouncement() {
      this.$router.push({ path: `/addAnnouncement` });
    },
    goToStock() {
      this.$router.push({ path: `/stock` });
    },
    goToClients() {
      this.$router.push({ path: `/clients` });
    },
    async logout() {
      await User.UserLogout();
      this.$router.push({ path: `/` });
    },
  },
};
</script>

<style></style>
