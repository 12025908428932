import firebase from "../setting/firebase";
import "firebase/auth";

export default class User {
  constructor(id, email, password) {
    this.id = id;
    this.email = email;
    this.password = password;
  }

  static async setUser(user) {
    await localStorage.setItem("User", JSON.stringify(user));
  }
  static async getUser() {
    let user = await localStorage.getItem("User");
    return JSON.parse(user);
  }
  static async deleteUser() {
    await localStorage.removeItem("User");
  }

  static async Login(email, password) {
    let user;
    await firebase
      .firestore()
      .collection("user")
      .where("email", "==", email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          user = doc.data();
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
    if (user && password == user.password) {
      await this.setUser(user);
      return true;
    } else {
      alert("Dados incorretos, tente novamente!");
    }
  }

  static async UserLogout() {
    await User.deleteUser();
  }
}
