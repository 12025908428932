<template>
  <div>
    <v-row><h3>Dados pessoais:</h3></v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          label="Nome"
          placeholder="Digite aqui"
          outlined
          v-model="dadosPessoais.nome"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-mask="'###.###.###-##'"
          label="CPF"
          placeholder="Digite aqui"
          outlined
          v-model="dadosPessoais.cpf"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="RG"
          placeholder="Digite aqui"
          outlined
          v-model="dadosPessoais.rg"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          label="Nome do pai"
          placeholder="Digite aqui"
          outlined
          v-model="dadosPessoais.nomeDoPai"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Nome da mãe"
          placeholder="Digite aqui"
          outlined
          v-model="dadosPessoais.nomeDaMae"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-mask="'(##)####-####'"
          label="Telefone"
          placeholder="Digite aqui"
          outlined
          v-model="dadosPessoais.telefone"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          v-mask="'(##)#####-####'"
          label="Celular"
          placeholder="Digite aqui"
          outlined
          v-model="dadosPessoais.celular"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Email"
          placeholder="Digite aqui"
          outlined
          type="email"
          v-model="dadosPessoais.email"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row><h3>Endereço:</h3></v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          label="Logradouro"
          placeholder="Digite aqui"
          outlined
          v-model="endereco.logradouro"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Número"
          placeholder="Digite aqui"
          outlined
          v-model="endereco.numero"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Bairro"
          placeholder="Digite aqui"
          outlined
          v-model="endereco.bairro"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          v-mask="'#####-###'"
          label="CEP"
          placeholder="Digite aqui"
          outlined
          v-model="endereco.cep"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Cidade"
          placeholder="Digite aqui"
          outlined
          v-model="endereco.cidade"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Estado"
          placeholder="Digite aqui"
          outlined
          v-model="endereco.estado"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row><h3>Vínculo empregatício</h3></v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          label="Empresa"
          placeholder="Digite aqui"
          outlined
          v-model="vinculoEmpregaticio.empresa"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Logradouro"
          placeholder="Digite aqui"
          outlined
          v-model="vinculoEmpregaticio.logradouroEmpresa"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Número"
          placeholder="Digite aqui"
          outlined
          v-model="vinculoEmpregaticio.numeroEmpresa"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          label="Bairro"
          placeholder="Digite aqui"
          outlined
          v-model="vinculoEmpregaticio.bairroEmpresa"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-mask="'#####-###'"
          label="CEP"
          placeholder="Digite aqui"
          outlined
          v-model="vinculoEmpregaticio.cepEmpresa"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Cidade"
          placeholder="Digite aqui"
          outlined
          v-model="vinculoEmpregaticio.cidadeEmpresa"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          label="Estado"
          placeholder="Digite aqui"
          outlined
          v-model="vinculoEmpregaticio.estadoEmpresa"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Renda"
          placeholder="Digite aqui"
          outlined
          type="number"
          v-model="vinculoEmpregaticio.renda"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Admissão"
          placeholder="Digite aqui"
          outlined
          v-model="vinculoEmpregaticio.admissao"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          v-mask="'(##)####-####'"
          label="Telefone"
          placeholder="Digite aqui"
          outlined
          v-model="vinculoEmpregaticio.telefoneEmpresa"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row><h3>Dados bancários</h3></v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          label="Banco"
          placeholder="Digite aqui"
          outlined
          v-model="dadosBancarios.banco"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Tipo de conta"
          placeholder="Digite aqui"
          outlined
          v-model="dadosBancarios.tipoConta"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Agência"
          placeholder="Digite aqui"
          outlined
          type="number"
          v-model="dadosBancarios.agencia"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          v-mask="'#####-#'"
          label="Número da conta"
          placeholder="Digite aqui"
          outlined
          v-model="dadosBancarios.numeroConta"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row><h3>Fotos dos documentos</h3></v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-file-input
          prepend-icon="mdi-camera"
          counter
          placeholder="CNH Frente"
          multiple
          show-size
          small-chips
          truncate-length="50"
          @change="uploadCnhFrente"
        ></v-file-input>
      </v-col>
      <v-col cols="12" md="4">
        <v-file-input
          prepend-icon="mdi-camera"
          counter
          placeholder="CNH Verso"
          multiple
          show-size
          small-chips
          truncate-length="50"
          @change="uploadCnhVerso"
        ></v-file-input>
      </v-col>
      <v-col cols="12" md="4">
        <v-file-input
          prepend-icon="mdi-camera"
          counter
          placeholder="Comprovante de residência"
          multiple
          show-size
          small-chips
          truncate-length="50"
          @change="uploadComprovanteResidencia"
        ></v-file-input>
      </v-col>
    </v-row>
    <div
      style="margin-top: 2rem; display:flex; align-items: center; justify-content: space-around; margin-bottom: 1rem"
    >
      <v-btn @click="registerFichaCadastral" color="accent" x-large>
        Registrar
      </v-btn>
    </div>
    <v-overlay :value="overlay"></v-overlay>
  </div>
</template>

<script>
import FichaCadastral from "../dominio/FichaCadastral";
import Announcement from "../dominio/Announcement";
import firebase from "../setting/firebase";
export default {
  name: "FichaCadastral",
  data() {
    return {
      overlay: false,
      dadosPessoais: {
        nome: "",
        rg: "",
        cpf: "",
        nomeDoPai: "",
        nomeDaMae: "",
        telefone: "",
        celular: "",
        email: "",
      },
      endereco: {
        logradouro: "",
        numero: "",
        bairro: "",
        cep: "",
        cidade: "",
        estado: "",
      },
      vinculoEmpregaticio: {
        empresa: "",
        logradouroEmpresa: "",
        numeroEmpresa: "",
        bairroEmpresa: "",
        cepEmpresa: "",
        cidadeEmpresa: "",
        estadoEmpresa: "",
        renda: 0,
        admissao: "",
        telefoneEmpresa: "",
      },
      dadosBancarios: {
        banco: "",
        tipoConta: "",
        agencia: 0,
        numeroConta: "",
      },
      fotosDocumentos: {
        cnhFrente: "",
        cnhVerso: "",
        comprovanteResidencia: "",
      },
      announcement: "",
    };
  },
  methods: {
    async registerFichaCadastral() {
      this.overlay = !this.overlay;
      var fotos = [
        this.fotosDocumentos.cnhFrente,
        this.fotosDocumentos.cnhVerso,
        this.fotosDocumentos.comprovanteResidencia,
      ];
      var storageRef,
        fileRef,
        fileURL = [],
        aux;
      for (let index = 0; index < fotos.length; index++) {
        storageRef = firebase.storage().ref();
        fileRef = storageRef.child(fotos[index][0].name);
        await fileRef.put(fotos[index][0]);
        aux = await fileRef.getDownloadURL();
        fileURL.push(aux);
      }
      this.announcement = await Announcement.getLocalAnnouncement();

      try {
        let request = await FichaCadastral.saveFichaCadastral(
          this.dadosPessoais,
          this.endereco,
          this.vinculoEmpregaticio,
          this.dadosBancarios,
          fileURL,
          this.announcement
        );
        if (request) {
          this.overlay = !this.overlay;
          alert("Enviado com sucesso!");
          this.$router.push({ path: `/` });
        }
      } catch (error) {
        this.overlay = !this.overlay;
        console.log(error);
      }
    },
    uploadCnhFrente(e) {
      this.fotosDocumentos.cnhFrente = e;
    },
    uploadCnhVerso(e) {
      this.fotosDocumentos.cnhVerso = e;
    },
    uploadComprovanteResidencia(e) {
      this.fotosDocumentos.comprovanteResidencia = e;
    },
  },
};
</script>

<style></style>
