<template>
  <div>
    <admin-menubar></admin-menubar>

    <v-main style="margin-top: 1rem; padding: 0 1rem">
      <h3
        style="margin-top: 1rem"
        class=" font-weight-bold mb-3 text-uppercase text-center"
      >
        Detalhes
      </h3>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            label="Marca"
            placeholder="Digite aqui"
            outlined
            v-model="marca"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Modelo"
            placeholder="Digite aqui"
            outlined
            v-model="modelo"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Ano"
            placeholder="Digite aqui"
            outlined
            v-model="ano"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            label="Carroceria"
            placeholder="Digite aqui"
            outlined
            v-model="carroceria"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Motor"
            placeholder="Digite aqui"
            outlined
            v-model="motor"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Combustível"
            placeholder="Digite aqui"
            outlined
            v-model="combustivel"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            label="Câmbio"
            placeholder="Digite aqui"
            outlined
            v-model="cambio"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Final da placa"
            placeholder="Digite aqui"
            outlined
            type="number"
            v-model="finalPlaca"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Cor"
            placeholder="Digite aqui"
            outlined
            v-model="cor"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            label="Quilometragem"
            placeholder="Digite aqui"
            outlined
            type="number"
            v-model="quilometragem"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Versão"
            placeholder="Digite aqui"
            outlined
            v-model="versao"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-file-input
            prepend-icon="mdi-camera"
            counter
            multiple
            show-size
            small-chips
            truncate-length="50"
            @change="uploadImage"
          ></v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            label="Preço"
            placeholder="Digite aqui"
            outlined
            type="number"
            v-model="preco"
          ></v-text-field>
        </v-col>
      </v-row>

      <h3
        style="margin-top: 1rem"
        class=" font-weight-bold mb-3 text-uppercase text-center"
      >
        Opcionais
      </h3>

      <v-textarea
        label="Opcionais"
        placeholder="Digite a lista de opcionais. Um por linha!"
        outlined
        v-model="opcionais"
      ></v-textarea>

      <div
        style="display:flex; align-items: center; justify-content: space-around; margin-bottom: 1rem"
      >
        <v-btn @click="regiterAnnouncement" color="accent" x-large>
          Registrar
        </v-btn>
        <v-btn @click="clearFields" dark x-large>
          Limpar
        </v-btn>
      </div>
    </v-main>
    <v-overlay :value="overlay"></v-overlay>
  </div>
</template>

<script>
import User from "../dominio/User";
import Annoucement from "../dominio/Announcement";
import AdminMenubar from "../components/AdminMenubar";
import firebase from "../setting/firebase";

export default {
  name: "AddAnnouncement",
  components: { AdminMenubar },
  data() {
    return {
      marca: "",
      modelo: "",
      ano: "",
      carroceria: "",
      motor: "",
      combustivel: "",
      finalPlaca: "",
      cor: "",
      quilometragem: 0,
      cambio: "",
      opcionais: "",
      versao: "",
      fotos: [],
      preco: 0,
      overlay: false,
    };
  },
  methods: {
    goToAdmin() {
      this.$router.push({ path: `/admin` });
    },
    async logout() {
      await User.UserLogout();
      this.$router.push({ path: `/` });
    },
    async regiterAnnouncement() {
      this.overlay = !this.overlay;
      var storageRef,
        fileRef,
        fileURL = [],
        aux;
      for (let index = 0; index < this.fotos.length; index++) {
        storageRef = firebase.storage().ref();
        fileRef = storageRef.child(this.fotos[index].name);
        await fileRef.put(this.fotos[index]);
        aux = await fileRef.getDownloadURL();
        fileURL.push(aux);
      }

      try {
        let request = await Annoucement.saveAnnouncement(
          this.marca,
          this.modelo,
          this.ano,
          this.carroceria,
          this.motor,
          this.combustivel,
          this.finalPlaca,
          this.cor,
          this.quilometragem,
          this.cambio,
          this.opcionais,
          this.versao,
          fileURL,
          this.preco
        );
        if (request) {
          this.overlay = !this.overlay;
          alert("Registrado com sucesso!");
          this.$router.push({ path: `/admin` });
        } else alert("Erro");
      } catch (error) {
        this.overlay = !this.overlay;
        console.log(error);
      }
      // this.clearFields();
    },
    clearFields() {
      this.marca = "";
      this.modelo = "";
      this.ano = "";
      this.carroceria = "";
      this.motor = "";
      this.combustivel = "";
      this.finalPlaca = "";
      this.cor = "";
      this.quilometragem = "";
      this.cambio = "";
      this.fotos = "";
      this.opcionais = "";
    },
    uploadImage(e) {
      this.fotos = e;
    },
    created() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style></style>
