import firebase from "../setting/firebase";
import "firebase/auth";
export default class Announcement {
  constructor(
    id,
    marca,
    modelo,
    ano,
    carroceria,
    motor,
    combustivel,
    finalPlaca,
    cor,
    quilometragem,
    cambio,
    opcionais,
    versao,
    fotos,
    preco
  ) {
    this.id = id;
    this.marca = marca;
    this.modelo = modelo;
    this.ano = ano;
    this.carroceria = carroceria;
    this.motor = motor;
    this.combustivel = combustivel;
    this.finalPlaca = finalPlaca;
    this.cor = cor;
    this.quilometragem = quilometragem;
    this.cambio = cambio;
    this.opcionais = opcionais;
    this.versao = versao;
    this.fotos = fotos;
    this.preco = preco;
  }

  static async setAnnouncement(user) {
    await localStorage.setItem("Announcement", JSON.stringify(user));
  }
  static async getAnnouncement() {
    let user = await localStorage.getItem("Announcement");
    return JSON.parse(user);
  }
  static async deleteAnnouncement(announcement) {
    try {
      await firebase
        .firestore()
        .collection("announcement")
        .doc(announcement.id)
        .delete();
      let request = await this.deletePhotos(announcement.fotos);
      if (request) return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  static async deletePhotos(fotos) {
    for (let index = 0; index < fotos.length; index++) {
      var storage = firebase.storage();
      var pictureRef = storage.refFromURL(fotos[index]);
      await pictureRef
        .delete()
        .then(() => {
          console.log("Deleted!");
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return true;
  }
  static async saveAnnouncement(
    marca,
    modelo,
    ano,
    carroceria,
    motor,
    combustivel,
    finalPlaca,
    cor,
    quilometragem,
    cambio,
    opcionais,
    versao,
    fotos,
    preco
  ) {
    try {
      let request = await firebase
        .firestore()
        .collection("announcement")
        .add({
          marca: marca,
          modelo: modelo,
          ano: ano,
          carroceria: carroceria,
          motor: motor,
          combustivel: combustivel,
          finalPlaca: finalPlaca,
          cor: cor,
          quilometragem: quilometragem,
          cambio: cambio,
          opcionais: opcionais,
          versao: versao,
          fotos: fotos,
          preco: preco,
        });
      if (request) {
        let announcement = new Announcement(
          request.id,
          marca,
          modelo,
          ano,
          carroceria,
          motor,
          combustivel,
          finalPlaca,
          cor,
          quilometragem,
          cambio,
          opcionais,
          versao,
          fotos,
          preco
        );
        await this.updateAnnouncement(announcement);
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  static async getServerAnnouncements() {
    let announcements = [];
    await firebase
      .firestore()
      .collection("announcement")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          announcements.push(doc.data());
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
    return announcements;
  }

  static async getSpecificServerAnnouncement(announcement) {
    try {
      let document = await firebase
        .firestore()
        .collection("announcement")
        .doc(announcement.id)
        .get();
      document = document.data();

      this.Announcement = new Announcement(
        document.id,
        document.marca,
        document.modelo,
        document.ano,
        document.carroceria,
        document.motor,
        document.combustivel,
        document.finalPlaca,
        document.cor,
        document.quilometragem,
        document.cambio,
        document.opcionais,
        document.versao,
        document.fotos,
        document.preco
      );
      this.setLocalAnnouncement(this.Announcement);
    } catch (error) {
      console.log(error);
    }
  }

  static async updateAnnouncement(announcement) {
    try {
      await firebase
        .firestore()
        .collection("announcement")
        .doc(announcement.id)
        .update({
          id: announcement.id,
          marca: announcement.marca,
          modelo: announcement.modelo,
          ano: announcement.ano,
          carroceria: announcement.carroceria,
          motor: announcement.motor,
          combustivel: announcement.combustivel,
          finalPlaca: announcement.finalPlaca,
          cor: announcement.cor,
          quilometragem: announcement.quilometragem,
          cambio: announcement.cambio,
          opcionais: announcement.opcionais,
          versao: announcement.versao,
          fotos: announcement.fotos,
          preco: announcement.preco,
          data: firebase.firestore.FieldValue.serverTimestamp(),
        });
      return true;
    } catch (error) {
      console.log(error);
    }
  }
  static async getLocalAnnouncement() {
    let Announcement = JSON.parse(await localStorage.getItem("Announcement"));

    return Announcement;
  }
  static async setLocalAnnouncement(Announcement) {
    await localStorage.setItem("Announcement", JSON.stringify(Announcement));
  }
  static async deleteLocalAnnouncement() {
    await localStorage.removeItem("Announcement");
  }
}
