import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../pages/Index.vue";
import Login from "../pages/Login.vue";
import Stock from "../pages/Stock.vue";
import AddAnnouncement from "../pages/AddAnnouncement.vue";
import EditAnnouncement from "../pages/EditAnnouncement.vue";
import MoreDetails from "../pages/MoreDetails.vue";
import Clients from "../pages/Clients.vue";
import ClientDetails from "../pages/ClientDetails.vue";
import User from "../dominio/User";

Vue.use(VueRouter);

async function guardRouter(to, from, next) {
  var isAuthenticated = false;

  if (await User.getUser()) isAuthenticated = true;
  else isAuthenticated = false;

  if (isAuthenticated) next();
  else next({ name: "Login" });
}

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/stock",
    name: "Stock",
    component: Stock,
    beforeEnter: guardRouter,
  },
  {
    path: "/addAnnouncement",
    name: "AddAnnouncement",
    component: AddAnnouncement,
    beforeEnter: guardRouter,
  },
  {
    path: "/editAnnouncement",
    name: "EditAnnouncement",
    component: EditAnnouncement,
    beforeEnter: guardRouter,
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    beforeEnter: guardRouter,
  },
  {
    path: "/clientDetails",
    name: "ClientDetails",
    component: ClientDetails,
    beforeEnter: guardRouter,
  },
  {
    path: "/maisDetalhes",
    name: "MoreDetails",
    component: MoreDetails,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
