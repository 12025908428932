<template>
  <div>
    <h4>ID: {{ announcement.id }}</h4>
    <h4>Modelo: {{ announcement.modelo }}</h4>
    <h4>Versão: {{ announcement.versao }}</h4>
    <h4>Ano: {{ announcement.ano }}</h4>
    <h4>Preço: {{ announcement.preco }}</h4>
    <h4>Câmbio: {{ announcement.cambio }}</h4>
    <h4>Carroceria: {{ announcement.carroceria }}</h4>
    <h4>Combustível: {{ announcement.combustivel }}</h4>
    <h4>Cor: {{ announcement.cor }}</h4>
    <h4>Final da placa: {{ announcement.finalPlaca }}</h4>
    <h4>Marca: {{ announcement.marca }}</h4>
    <h4>Motor: {{ announcement.motor }}</h4>
    <h4>Quilometragem: {{ announcement.quilometragem }}</h4>

    <v-btn
      @click="editAnnouncement(announcement)"
      style="margin: 1rem 1rem 1rem 0"
      dark
      x-large
    >
      Editar
    </v-btn>
    <v-btn @click="deleteAnnouncement(announcement)" color="error" x-large>
      Excluir
    </v-btn>
  </div>
</template>

<script>
import Announcement from "../dominio/Announcement";
export default {
  name: "AnnouncementDetails",
  props: {
    announcement: Object,
  },
  data() {
    return {};
  },
  methods: {
    async editAnnouncement(announcement) {
      await Announcement.setLocalAnnouncement(announcement);
      this.$router.push({ path: `/editAnnouncement` });
    },
    async deleteAnnouncement(announcement) {
      let request = await Announcement.deleteAnnouncement(announcement);
      if (request) alert("Removido com sucesso");
      else alert("Erro ao remover");
      this.$router.go();
    },
  },
};
</script>

<style></style>
