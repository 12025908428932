<template>
  <div>
    <v-app-bar app color="white" height="100">
      <v-toolbar-title id="logo-name" class="font-weight-black headline">
        S CAR
      </v-toolbar-title>
      <v-btn plain>
        <a @click="$vuetify.goTo('#stock')">Estoque</a>
      </v-btn>
      <v-btn plain>
        <a @click="$vuetify.goTo('#contact')">Contato</a>
      </v-btn>
    </v-app-bar>

    <v-content>
      <section id="hero">
        <v-row no-gutters>
          <v-img
            :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
            src="https://images.unsplash.com/photo-1508974239320-0a029497e820?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
          >
            <v-theme-provider dark>
              <v-container fill-height>
                <v-row
                  align="center"
                  class="white--text mx-auto"
                  justify="center"
                >
                  <v-col class="white--text text-center" cols="12" tag="h1">
                    <span
                      :class="[
                        $vuetify.breakpoint.smAndDown
                          ? 'display-1'
                          : 'display-2',
                      ]"
                      class="font-weight-light"
                    >
                      COMPRE OU VENDA AGORA
                    </span>

                    <br />

                    <span
                      :class="[
                        $vuetify.breakpoint.smAndDown
                          ? 'display-3'
                          : 'display-4',
                      ]"
                      class="font-weight-black"
                    >
                      SEU CARRO
                    </span>
                  </v-col>

                  <v-btn
                    class="align-self-end"
                    fab
                    outlined
                    @click="$vuetify.goTo('#stock')"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-theme-provider>
          </v-img>
        </v-row>
      </section>

      <section id="stock" ref="stock">
        <div class="py-12"></div>

        <v-container>
          <h2
            class="display-2 font-weight-bold mb-3 text-uppercase text-center"
          >
            ESTOQUE
          </h2>

          <v-responsive class="mx-auto mb-12" width="56">
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-row>
            <v-col
              v-for="(announcement, i) in announcements"
              :key="i"
              cols="12"
              md="4"
            >
            <carousel :perPage="1">
              <slide v-for="(foto, i) in announcement.fotos" :key="i">
                <img :src="announcement.fotos[i]" width="100%"/>
              </slide>
            </carousel>

              <h3 class="font-weight-black mb-4 text-uppercase">
                Modelo: {{ announcement.modelo }}
              </h3>
              <div class="title font-weight-light mb-5">
                <b>Versão: </b>{{ announcement.versao }}
              </div>
              <div class="title font-weight-light mb-5">
                <b>Ano: </b>{{ announcement.ano }}
              </div>
              <div class="title font-weight-light mb-5">
                <b>Preço: </b>{{ announcement.preco }}
              </div>

              <v-btn
                @click="moreDetails(announcement)"
                color="blue-grey"
                class="white--text"
              >
                Ver mais detalhes
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <div class="py-12"></div>
      </section>
      <section ref="contact">
        <v-sheet id="contact" color="#333333" dark tag="section" tile>
          <div class="py-12"></div>

          <v-container>
            <h2
              style="overflow-wrap: break-word" class="display-2 font-weight-bold mb-3 text-uppercase text-center"
            >
              ENTRE EM CONTATO
            </h2>
            <h3 style="overflow-wrap: break-word" class="font-weight-bold mb-3 text-uppercase text-center">
              TELEFONE: (11) 2254-0034
            </h3>
            <h3 style="overflow-wrap: break-word" class="font-weight-bold mb-3 text-uppercase text-center">
              <strong>EMAIL: SCARVEICULOS01@GMAIL.COM</strong>
            </h3>
            <h3 style="overflow-wrap: break-word" class="font-weight-bold mb-3 text-uppercase text-center">
              ENDEREÇO: RUA MACIEL MONTEIRO, 865, ARTHUR ALVIM - VILA NHOCUNÉ - CEP
              03566-000 - SP.
            </h3>

            <v-responsive class="mx-auto mb-12" width="56">
              <v-divider class="mb-1"></v-divider>

              <v-divider></v-divider>
            </v-responsive>
          </v-container>

          <div class="py-12"></div>
        </v-sheet>
      </section>
    </v-content>

    <v-footer class="justify-center" color="#292929" height="100">
      <div
        style="margin-right: 1rem"
        class="title font-weight-light grey--text text--lighten-1 text-center"
      >
        &copy; {{ new Date().getFullYear() }}
      </div>
      <v-btn @click="goToLogin">ADM</v-btn>
    </v-footer>
  </div>
</template>

<script>
import Announcement from "../dominio/Announcement";
export default {
  name: "Index",

  data() {
    return {
      announcements: [],
    };
  },
  methods: {
    goToLogin() {
      this.$router.push({ path: `/login` });
    },
    async moreDetails(announcement) {
      await Announcement.getSpecificServerAnnouncement(announcement);
      this.$router.push({
        path: `/maisDetalhes`,
      });
    },
  },
  async mounted() {
    this.announcements = await Announcement.getServerAnnouncements();
  },
};
</script>

<style lang="scss" scoped>
h3 span {
  display: inline-block;
}
</style>
