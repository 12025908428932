<template>
  <div>
    <v-app-bar app color="white" height="100">
      <v-toolbar-title id="logo-name" class="font-weight-black headline">
        S CAR
      </v-toolbar-title>
      <v-btn plain>
        <a @click="goToHome">Home</a>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>
          <v-divider></v-divider>
        </v-responsive>
        <carousel :perPage="1">
          <slide v-for="(foto, i) in announcement.fotos" :key="i">
            <img :src="announcement.fotos[i]" width="100%"/>
          </slide>
        </carousel>

        <div style="display: flex; justify-content: center"></div>
        <v-card>
          <v-toolbar flat color="blue-grey" dark>
            <v-toolbar-title>Informações</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-list three-line>
              <template>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><h3>
                        Modelo: {{ announcement.modelo }}
                      </h3></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><h3>
                        Versão: {{ announcement.versao }}
                      </h3></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><h3>Ano: {{ announcement.ano }}</h3></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><h3>
                        Preço: {{ announcement.preco }}
                      </h3></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><h3>
                        Câmbio: {{ announcement.cambio }}
                      </h3></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><h3>
                        Carroceria: {{ announcement.carroceria }}
                      </h3></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><h3>
                        Combustível: {{ announcement.combustivel }}
                      </h3></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><h3>Cor: {{ announcement.cor }}</h3></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><h3>
                        Final da placa: {{ announcement.finalPlaca }}
                      </h3></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><h3>
                        Marca: {{ announcement.marca }}
                      </h3></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><h3>
                        Motor: {{ announcement.motor }}
                      </h3></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><h3>
                        Quilometragem: {{ announcement.quilometragem }}
                      </h3></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><h3>
                        Opcionais:
                        <h4
                          v-for="(opcao, i) in announcement.opcionais"
                          :key="i"
                        >
                          {{ opcao }}
                        </h4>
                      </h3></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>

          <v-divider></v-divider>
        </v-card>
        <v-card style="margin-top: 1rem">
          <v-toolbar flat color="blue-grey" dark>
            <v-toolbar-title>Envio de Ficha Cadastral</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-expansion-panels accordion>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <p style="font-size: 18px">
                    <strong>Dados</strong>
                  </p>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ficha-cadastral></ficha-cadastral>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Announcement from "../dominio/Announcement";
import FichaCadastral from "./FichaCadastral.vue";
export default {
  components: { FichaCadastral },
  name: "MoreDetails",
  data() {
    return {
      announcement: Object,
    };
  },
  methods: {
    goToHome() {
      this.$router.push({ path: `/` });
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
  async mounted() {
    this.announcement = await Announcement.getLocalAnnouncement();
    this.announcement.opcionais = this.announcement.opcionais.split("\n");
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 500px) {
  #logo-name {
    display: none;
  }
}
</style>
