import firebase from "../setting/firebase";

export default class FichaCadastral {
  constructor(
    id,
    announcement,
    dadosPessoais,
    dadosBancarios,
    vinculoEmpregaticio,
    endereco,
    fotosDocumentos
  ) {
    this.id = id;
    this.announcement = announcement;
    this.dadosPessoais = dadosPessoais;
    this.dadosBancarios = dadosBancarios;
    this.vinculoEmpregaticio = vinculoEmpregaticio;
    this.endereco = endereco;
    this.fotosDocumentos = fotosDocumentos;
  }
  static async saveFichaCadastral(
    dadosPessoais,
    endereco,
    vinculoEmpregaticio,
    dadosBancarios,
    fileURL,
    announcement
  ) {
    try {
      var request = await firebase
        .firestore()
        .collection("fichaCadastral")
        .add({
          dadosPessoais: dadosPessoais,
          endereco: endereco,
          vinculoEmpregaticio: vinculoEmpregaticio,
          dadosBancarios: dadosBancarios,
          fotosDocumentos: fileURL,
          announcement: announcement,
        });
      if (request) {
        let fichaCadastral = new FichaCadastral(
          request.id,
          announcement,
          dadosPessoais,
          dadosBancarios,
          vinculoEmpregaticio,
          endereco,
          fileURL
        );
        await this.updateFichaCadastral(fichaCadastral);
      }
    } catch (error) {
      console.log(error);
    }
    if (request) return true;
  }
  static async updateFichaCadastral(fichaCadastral) {
    try {
      await firebase
        .firestore()
        .collection("fichaCadastral")
        .doc(fichaCadastral.id)
        .update({
          id: fichaCadastral.id,
          dadosPessoais: fichaCadastral.dadosPessoais,
          endereco: fichaCadastral.endereco,
          vinculoEmpregaticio: fichaCadastral.vinculoEmpregaticio,
          dadosBancarios: fichaCadastral.dadosBancarios,
          fotosDocumentos: fichaCadastral.fotosDocumentos,
          announcement: fichaCadastral.announcement,
          data: firebase.firestore.FieldValue.serverTimestamp(),
        });
      return true;
    } catch (error) {
      console.log(error);
    }
  }
  static async getAllFichaCadastral() {
    var fichas = [];
    try {
      await firebase
        .firestore()
        .collection("fichaCadastral")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            fichas.push(doc.data());
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
      return fichas;
    } catch (error) {
      console.log(error);
    }
  }
  static async getSpecificFichaCadastral(client) {
    var response;
    try {
      await firebase
        .firestore()
        .collection("fichaCadastral")
        .where("dadosPessoais.cpf", "==", client.dadosPessoais.cpf)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            response = doc.data();
          });
        })
        .catch((error) => {
          console.log(error);
        });
      this.setLocalFichaCadastral(response);
    } catch (error) {
      console.log(error);
    }
  }
  static async deleteFichaCadastral(client) {
    console.log(client.id);
    await firebase
      .firestore()
      .collection("fichaCadastral")
      .doc(client.id)
      .delete();
    let request = await this.deletePhotos(client.fotosDocumentos);
    if (request) return true;
  }
  static async deletePhotos(fotos) {
    for (let index = 0; index < fotos.length; index++) {
      var storage = firebase.storage();
      var pictureRef = storage.refFromURL(fotos[index]);
      await pictureRef
        .delete()
        .then(() => {
          console.log("Deleted!");
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return true;
  }
  static async getLocalFichaCadastral() {
    let client = JSON.parse(await localStorage.getItem("client"));
    return client;
  }
  static async setLocalFichaCadastral(client) {
    await localStorage.setItem("client", JSON.stringify(client));
  }
}
