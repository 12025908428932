<template>
  <div>
    <admin-menubar></admin-menubar>
    <v-list two-line>
      <v-list-item v-for="client in clients" :key="client.dadosPessoais.cpf">
        <v-list-item-avatar>
          <v-icon class="grey lighten-1" dark>
            mdi-folder
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            v-text="client.dadosPessoais.nome"
          ></v-list-item-title>

          <v-list-item-subtitle
            v-text="client.dadosPessoais.cpf"
          ></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon @click="goToClientDetails(client)">
            <v-icon color="grey lighten-1">mdi-information</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import AdminMenubar from "../components/AdminMenubar";
import FichaCadastral from "../dominio/FichaCadastral";
export default {
  name: "Clients",
  components: { AdminMenubar },
  data: () => ({
    clients: [],
  }),
  methods: {
    async goToClientDetails(client) {
      await FichaCadastral.getSpecificFichaCadastral(client);
      this.$router.push({ path: `/clientDetails` });
    },
  },
  async created() {
    this.clients = await FichaCadastral.getAllFichaCadastral();
  },
};
</script>

<style></style>
