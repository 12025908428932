import firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

var firebaseConfig = {
    apiKey: "AIzaSyD05vMaHiGTP2GBijgVCD67nTtJ8L-emRk",
    authDomain: "sidveiculos-b2e6e.firebaseapp.com",
    databaseURL: "https://sidveiculos-b2e6e-default-rtdb.firebaseio.com",
    projectId: "sidveiculos-b2e6e",
    storageBucket: "sidveiculos-b2e6e.appspot.com",
    messagingSenderId: "8622702929",
    appId: "1:8622702929:web:cfdcd67aa93f98c3d75370",
    measurementId: "G-J0F9NVVZGR"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;