<template>
  <div>
    <admin-menubar></admin-menubar>
    <v-main>
      <v-container>
        <h2 class="display-1 font-weight-bold mb-3 text-uppercase text-center">
          ESTOQUE
        </h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row>
          <v-col
            v-for="(announcement, i) in announcements"
            :key="i"
            cols="12"
            md="4"
          >
            <carousel :perPage="1">
              <slide v-for="(foto, i) in announcement.fotos" :key="i">
                <img :src="announcement.fotos[i]" width="100%"/>
              </slide>
            </carousel>
            <v-expansion-panels accordion>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <p style="font-size: 18px">
                    <strong>Dados</strong>
                  </p>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <announcement-details :announcement="announcement"></announcement-details>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import AdminMenubar from "../components/AdminMenubar.vue";
import AnnouncementDetails from '../components/AnnouncementDetails.vue';
import Announcement from "../dominio/Announcement";
export default {
  components: { AdminMenubar, AnnouncementDetails },
  name: "Stock",
  data() {
    return {
      announcements: [],
    };
  },
  async mounted() {
    this.announcements = await Announcement.getServerAnnouncements();
  },
};
</script>

<style lang="scss" scoped>
.info-fields {
  display: flex;
  justify-content: space-between;
}
</style>
