import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from "./router";
import VueCarousel from 'vue-carousel';
import VueTheMask from 'vue-the-mask'

Vue.use(VueTheMask);
Vue.use(VueCarousel);
Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
