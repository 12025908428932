<template>
  <div>
    <admin-menubar></admin-menubar>
    <v-main>
      <v-container>
        <div style="display: flex; justify-content: center"></div>
        <v-card>
          <v-toolbar flat color="blue-grey" dark>
            <v-toolbar-title>Informações</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-list three-line>
              <template>
                <h2 style="margin: 1rem 0; color: green">Anúncio</h2>
                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>ID:</strong>
                      {{ client.announcement.id }}
                    </p>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Modelo:</strong>
                      {{ client.announcement.modelo }}
                    </p>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <h2 style="margin: 1rem 0; color: green">Dados pessoais</h2>

                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Nome:</strong>
                      {{ client.dadosPessoais.nome }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Celular:</strong>
                      {{ client.dadosPessoais.celular }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>CPF:</strong>
                      {{ client.dadosPessoais.cpf }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>RG:</strong>
                      {{ client.dadosPessoais.rg }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Nome da mãe:</strong>
                      {{ client.dadosPessoais.nomeDoMae }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Nome do pai:</strong>
                      {{ client.dadosPessoais.nomeDoPai }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Email:</strong>
                      {{ client.dadosPessoais.email }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Telefone:</strong>
                      {{ client.dadosPessoais.telefone }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <h2 style="margin: 1rem 0; color: green">Endereço</h2>

                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Logradouro:</strong>
                      {{ client.endereco.logradouro }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Número:</strong>
                      {{ client.endereco.numero }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Bairro:</strong>
                      {{ client.endereco.bairro }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>CEP:</strong>
                      {{ client.endereco.cep }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Cidade:</strong>
                      {{ client.endereco.cidade }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Estado:</strong>
                      {{ client.endereco.estado }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Logradouro:</strong>
                      {{ client.endereco.logradouro }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <h2 style="margin: 1rem 0; color: green">
                  Vínculo empregatício
                </h2>

                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Empresa:</strong>
                      {{ client.vinculoEmpregaticio.empresa }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Admissão:</strong>
                      {{ client.vinculoEmpregaticio.admissao }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Renda:</strong>
                      {{ client.vinculoEmpregaticio.renda }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Telefone:</strong>
                      {{ client.vinculoEmpregaticio.telefoneEmpresa }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Logradouro:</strong>
                      {{ client.vinculoEmpregaticio.logradouroEmpresa }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Número:</strong>
                      {{ client.vinculoEmpregaticio.numeroEmpresa }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Bairro:</strong>
                      {{ client.vinculoEmpregaticio.bairroEmpresa }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>CEP:</strong>
                      {{ client.vinculoEmpregaticio.cepEmpresa }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Cidade:</strong>
                      {{ client.vinculoEmpregaticio.cidadeEmpresa }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Estado:</strong>
                      {{ client.vinculoEmpregaticio.estadoEmpresa }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <h2 style="margin: 1rem 0; color: green">
                  Dados bancários
                </h2>

                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Banco:</strong>
                      {{ client.dadosBancarios.banco }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Tipo de conta:</strong>
                      {{ client.dadosBancarios.tipoConta }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Agência:</strong>
                      {{ client.dadosBancarios.agencia }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Conta:</strong>
                      {{ client.dadosBancarios.numeroConta }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <h2 style="margin: 1rem 0; color: green">
                  Fotos dos documentos
                </h2>

                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>CNH Frente:</strong>
                      <img :src="client.fotosDocumentos[0]" width="100%" />
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>CNH Verso:</strong>
                      <img :src="client.fotosDocumentos[1]" width="100%" />
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <p>
                      <strong>Comprovante de residência:</strong>
                      <img :src="client.fotosDocumentos[2]" width="100%" />
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-container>
      <div class="delete-button">
        <v-btn
          @click="deleteClient()"
          class="delete-button"
          color="error"
          x-large
        >
          Excluir
        </v-btn>
      </div>
    </v-main>
  </div>
</template>

<script>
import AdminMenubar from "../components/AdminMenubar";
import FichaCadastral from "../dominio/FichaCadastral";
export default {
  name: "ClientDetails",
  data() {
    return {
      client: Object,
    };
  },
  components: { AdminMenubar },
  methods: {
    async deleteClient() {
      let request = await FichaCadastral.deleteFichaCadastral(this.client);
      if (request) alert("Removido com sucesso!");
      else alert("Erro ao remover");
      this.$router.push({ path: `/clients` });
    },
  },
  async created() {
    this.client = await FichaCadastral.getLocalFichaCadastral();
    console.log(this.client);
  },
};
</script>

<style lang="scss">
p {
  word-break: break-word;
  font-size: 16px;
}
.delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
}
</style>
